<template>
  <footer id="footer">
    <div class="cont-wrap">
      <div class="logo-wrap">
        <router-link to="/Main" class="foot-logo">
          <img src="@/assets/images/common/logo-w.svg" alt="cosmocos lab logo" />
        </router-link>
        <a href="https://www.ktng.com/" target="_blank" class="ktng-logo">
          <span>A Family of</span>
          <img src="@/assets/images/common/logo-ktng.svg" alt="kt&g logo" />
        </a>
      </div>
      <div class="footer-wrap">
        <div class="foot-info">
          <ul class="fnb">
            <li>
              <button type="button" @click="showTermModal('privacy')">
                {{ $t('foot.privacy') }}
              </button>
            </li>
            <li>
              <button type="button" @click="showTermModal('terms')">
                {{ $t('foot.use') }}
              </button>
            </li>
            <li>
              <button type="button" @click="affiliateInquery" v-if="locale === 'ko'">
                {{ $t('foot.affiliate') }}
              </button>
            </li>
          </ul>
          <ul class="info">
            <li>
              {{ $t('foot.logo') }}
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.add_label') }}</dt>
                <dd>{{ $t('foot.add') }}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.ceo_label') }}</dt>
                <dd>{{ $t('foot.ceo') }}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.rnum_label') }}</dt>
                <dd>{{ $t('foot.rnum') }}</dd>
              </dl>
            </li>
          </ul>
          <!-- <select class="sel-family" onchange="if(this.value) window.open(this.value);">
            <option>Family site</option>
            <option value="https://www.ktng.com/">KT&G</option>
            <option value="https://cosmocos.com/">COSMOCOS</option>
          </select> -->
          <div class="sel-like-family" :class="{ active: isFamilyBind }" @click="familyMenu">
            <a href="javascript:;">Family site</a>
            <div class="drop">
              <ul>
                <li><a href="https://www.ktng.com/" target="_blank">KT&G</a></li>
                <li><a href="https://cosmocos.com/" target="_blank">COSMOCOS</a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- close .foot-info -->

        <div class="foot-common foot-sitemap">
          <h5>{{ $t('foot.sitemap_h') }}</h5>
          <ul>
            <li>
              <router-link to="/about/Lab"> ABOUT </router-link>
            </li>
            <li>
              <router-link to="/odm/Formula"> ODM </router-link>
            </li>
            <li>
              <router-link to="/open/Formula"> OPEN ODM </router-link>
            </li>
            <li>
              <router-link to="/obm/Inventory"> OBM </router-link>
              <!--              <a href="javacript:" @click="menuAccess"> OBM </a>-->
            </li>
            <li>
              <router-link to="/trend/Report"> TREND & NEWS </router-link>
            </li>
          </ul>
        </div>
        <!-- close .foot-sitemap -->

        <div class="foot-common foot-inquery">
          <h5>{{ $t('foot.inquery_h') }}</h5>
          <ul>
            <li>
              <dl>
                <dt>{{ $t('foot.odm_dome') }}</dt>
                <dd>
                  <a href="tel:+82 2-2166-7805">02-2166-7805</a><br />
                  <a href="mailto:odm@cosmocos.com" class="email">odm@cosmocos.com</a>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.odm_over') }}</dt>
                <dd>
                  <a href="tel:+82 02-2166-7994">02-2166-7994</a><br />
                  <a href="mailto:cosmocos.odm@gmail.com" class="email">cosmocos.odm@gmail.com</a>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.obm') }}</dt>
                <dd>
                  <a href="tel:+82 2-2166-7749">02-2166-7749</a><br />
                  <a href="mailto:odm@cosmocos.com" class="email">odm@cosmocos.com</a>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.b_hours_label') }}</dt>
                <dd v-html="$t('foot.b_hours')"></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{{ $t('foot.launch') }}</dt>
                <dd>11:30 - 12:30</dd>
              </dl>
            </li>
            <!-- <li>
                <dl>
                    <dt>E-mail :</dt>
                    <dd><a href="mailto:odm@cosmocos.com" class="email">odm@cosmocos.com</a></dd>
                </dl>
            </li> -->
          </ul>
        </div>
        <!-- close .foot-inquery -->
      </div>
      <div class="copyright">© 2022, COSMOCOS., LTD. All rights reserved.</div>
    </div>
    <!-- close .cont-wrap -->
    <span itemscope="" itemtype="http://schema.org/Organization">
      <link itemprop="url" href="https://cosmocoslab.com/" />
      <a itemprop="sameAs" href="https://www.instagram.com/cosmocoslab"></a>
      <a itemprop="sameAs" href="https://www.youtube.com/@COSMOCOSLab"></a>
    </span>
  </footer>

  <div class="floating-menu">
    <ul>
      <li>
        <button type="button" @click="openLabLibrary">
          <figure>
            <img src="@/assets/images/common/ico-32-library.svg" alt="icon" />
            <figcaption>LAB Library</figcaption>
          </figure>
        </button>
      </li>
      <li>
        <button type="button" @click="openUserGuide">
          <figure>
            <img src="@/assets/images/common/ico-32-userguide.svg" alt="icon" />
            <figcaption>User guide</figcaption>
          </figure>
        </button>
      </li>
      <li>
        <div class="badge-count" v-if="false">2</div>
        <a href="javascript:" @click="menuAccess('odm')">
          <figure>
            <img src="@/assets/images/common/ico-32-odmrequest.svg" alt="icon" />
            <figcaption>ODM Request</figcaption>
          </figure>
        </a>
      </li>
      <li>
        <div class="badge-count" v-if="false">1</div>
        <a href="javascript:" @click="menuAccess('obm')">
          <figure>
            <img src="@/assets/images/common/ico-32-obmrequest.svg" alt="icon" />
            <figcaption>OBM Request</figcaption>
          </figure>
        </a>
      </li>
      <li>
        <router-link to="/common/Faq">
          <figure>
            <img src="@/assets/images/common/ico-32-faq.svg" alt="icon" />
            <figcaption>FAQ</figcaption>
          </figure>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- close .floating-menu -->

  <ModalAlert ref="alertModal"></ModalAlert>
  <ModalAssociateMem ref="memModal" :modal-type="modalType" :modal-content="modalContent"></ModalAssociateMem>
  <ModalTerm ref="termModal" :modal-type="termModalType"></ModalTerm>
  <UserGuide ref="userModal" :guide-type="guideType"></UserGuide>
  <LabLibrary ref="labLibModal"></LabLibrary>

  <!--  <div class="dummy-box" v-if="false">-->
  <!--    <button type="button" @click="showModal">ModalAlert</button>-->
  <!--    <button type="button" @click="showMemModal">ModalAssociateMem</button>-->
  <!--  </div>-->
</template>

<script>
import ModalAlert from '@/components/ModalAlert.vue';
import ModalAssociateMem from '@/components/ModalAssociateMem.vue';
import ModalTerm from '@/components/ModalTerm.vue';
import UserGuide from '@/components/UserGuide.vue';
import LabLibrary from '@/components/LabLibrary.vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AppFooter',
  components: {
    LabLibrary,
    ModalAlert,
    ModalAssociateMem,
    ModalTerm,
    UserGuide,
  },
  watch: {
    '$i18n.locale': {
      handler(val) {
        this.locale = val;
        // this.getSearchConfig();
        // this.loadData();
      },
      deep: true,
    },
  },
  data() {
    const { t } = useI18n();
    return {
      t,
      isModalBind: false,
      isModalMemBind: false,
      isModalTerm: false,
      isUserGuide: false,
      isFamilyBind: false,
      guideType: 'ODM',
      modalType: null,
      termModalType: null,
      modalContent: null,
      locale: 'ko',
      userInfo: {},
    };
  },
  mounted() {
    this.locale = this.$i18n.locale;
    if (this.$cookies.get('accessToken')) {
      this.userInfo = this.$store.getters.getUserInfo;
    }

    this.emitter.on('OpenGuide', (val) => {
      this.$log.debug(['OpenGuide subscrib' + '' + 'e', val]);
      this.guideType = val;
      this.$log.debug(['openUserGuide', this.$refs.userModal]);
      this.openUserGuide();
    });

    this.emitter.on('OpenModal', (val) => {
      this.modalType = val;
      this.showMemModal();
    });
  },
  methods: {
    familyMenu() {
      this.isFamilyBind = !this.isFamilyBind;
    },
    odmMenu() {
      if (
        (this.userInfo.user_type === 'USER_TYPE02' || this.userInfo.user_type === 'USER_TYPE04') &&
        this.userInfo.user_status === 'USER_STATUS05'
      ) {
        this.$router.push('/odm/Request');
      } else {
        alert(this.t('system.not_access'));
      }
    },
    serviceReady() {
      alert(this.t('system.service_ready'));
    },
    menuAccess(type) {
      if (
        (this.userInfo.user_type === 'USER_TYPE02' || this.userInfo.user_type === 'USER_TYPE04') &&
        this.userInfo.user_status === 'USER_STATUS05'
      ) {
        if (type === 'odm') {
          this.$router.push('/odm/Request');
        } else {
          this.$router.push('/obm/Request');
        }
      } else {
        if (type === 'odm' || type === 'obm') {
          alert(this.t('system.odm_not_access'));
        } else {
          alert(this.t('system.not_access'));
        }
      }

      // const accessId = ['company', 'seumconsult', 'seumconsulting', 'engcompany1'];
      //
      // if (this.userInfo && accessId.includes(this.userInfo.user_id)) {
      //   this.$router.push('/obm/Inventory');
      // } else {
      //   alert('준비중입니다.');
      // }
    },
    openUserGuide() {
      this.isUserGuide = !this.isUserGuide;
      this.$refs.userModal.isShowUser = true;
      this.modalOverAdd();
    },
    openLabLibrary() {
      // this.isUserGuide = !this.isUserGuide;
      this.$refs.labLibModal.isShowUser = true;
      this.modalOverAdd();
    },
    closeUserGuide() {
      this.isUserGuide = false;
      this.modalOverRemove();
    },
    showModal() {
      this.isModalBind = true;
      this.$refs.alertModal.isShow = true;
      this.modalOverAdd();
    },
    showMemModal() {
      this.isModalMemBind = true;
      this.$refs.memModal.isShowMem = true;
      this.modalOverAdd();
    },
    showTermModal(val) {
      // alert('준비중입니다.');
      // this.isModalTerm = true;
      this.termModalType = val;
      this.$refs.termModal.isShowTerm = true;
      // this.modalOverAdd();
    },
    affiliateInquery() {
      // alert('준비중입니다.');
      if (this.$cookies.get('accessToken')) {
        const user = this.$store.getters.getUserInfo;
        if (user.user_type === 'USER_TYPE01') {
          this.modalType = 'custom';
          this.modalContent = '기업회원만 이용하실 수 있습니다.<br>기업회원으로 가입해주세요.';
          // this.$refs.alertModal.isShow = true;
          // this.$refs.memModal.isShow = true;
          this.$refs.memModal.isShowMem = true;
        } else {
          this.$log.debug(['']);
          if (user.user_type === 'USER_TYPE02') {
            if (user.is_partner) {
              switch (user.partner_status) {
                case 'PARTNER_STATUS00':
                  this.modalType = 'stop';
                  // this.modalContent = '이미 제휴사입니다.';
                  this.$refs.memModal.isShowMem = true;
                  break;
                case 'PARTNER_STATUS01':
                  this.modalType = 'custom';
                  this.modalContent = '검토중입니다.';
                  this.$refs.memModal.isShowMem = true;
                  break;
                case 'PARTNER_STATUS02':
                  this.modalType = 'custom';
                  this.modalContent = '이미 제휴사입니다.';
                  this.$refs.memModal.isShowMem = true;
                  break;
              }
            } else {
              // 이동
              this.$router.push('/mypage/CheckAuth');
            }
          }
        }
      } else {
        this.modalType = 'regular';
        // this.$refs.alertModal.isShow = true;
        // this.$refs.memModal.isShow = true;
        this.$refs.memModal.isShowMem = true;
      }
      /*
          if 비회원인 경우
              component > ModalAssociateMem : [v-if 준회원 안내팝업] (회원가입 유도)
          if 회원인 경우
              아래 실행
                  페이지 이동 후 비밀번호 입력 후 회원정보 수정페이지 하단
                  [COSMOCOS LAB 제휴 및 협력사 신청 (선택)]
                  섹션으로 스크롤 이동. 앵커사용 )
      */
      // this.$router.push('/mypage/CheckAuth');
    },
    modalOverAdd() {
      document.documentElement.classList.add('modal-overflow');
      document.body.classList.add('modal-overflow');
    },
    modalOverRemove() {
      document.documentElement.classList.remove('modal-overflow');
      document.body.classList.remove('modal-overflow');
    },
  },
};
</script>

<style scoped lang="scss">
.dummy-box {
  position: fixed;
  bottom: 72px;
  left: 24px;
  z-index: 999999999999;
  > button {
    display: inline-block;
    background-color: #ea3a3a;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 10px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    margin-right: 8px;
  }
}
.sel-like-family {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #000;
  color: #fff;
  width: 156px;
  z-index: 10;
  > a {
    display: block;
    border: 1px solid;
    border-color: #fff;
    width: 100%;
    padding: 10px 12px;
    background-image: url('@/assets/images/common/ico-20-sel-w.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right 6px center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #202020;
    }
  }
  > .drop {
    display: none;
    position: absolute;
    bottom: 48px;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    > ul {
      border: 1px solid #fff;
      > li {
        > a {
          display: block;
          width: 100%;
          padding: 10px 10px;
          font-size: 14px;
          line-height: 18px;
          color: #fff;
          background-color: #000;
          &:hover {
            background-color: #202020;
          }
        }
      }
    }
  }
  &.active {
    > .drop {
      display: block;
      opacity: 1;
    }
  }
}
@media (max-width: 1050px) {
  .sel-like-family {
    position: relative;
    left: auto;
    bottom: auto;
    > .drop {
      bottom: auto;
      top: 48px;
    }
  }
}
</style>
